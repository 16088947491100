import "../../css/Modal.css"
import useFadeInAnimation from "../../hooks/animationHooks";
import { useOutsideAlerter } from "../../hooks/useOutsideAlerter"

export const Modal = ({ children, headerText, themeClass, handleSetDisplayModal, footerContents = null, modalStyle=null }) => {
    const { ref, setShouldAnimate } = useFadeInAnimation(true);
    const checkForExitClick = useOutsideAlerter(ref, () => handleSetDisplayModal(false))
    const modalClass = themeClass ? `modal ${themeClass}` : "modal";

    return (
        <div className={modalClass}>
            <div ref={ref} className="modal-content" style={modalStyle}>
                <div onClick={() => handleSetDisplayModal(false)} className="modal-close"><i className="fa-regular fa-times"></i></div>
                <div className="modal-header">{headerText}</div>
                <div className="modal-content-inner">
                    {children}
                </div>
                {footerContents && <div className="modal-footer">{footerContents}</div>}
            </div>
        </div>
    )
}