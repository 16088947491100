import { useState, useEffect } from "react";
import { gameSettings } from "../../utils/constants";
import { getGamePlayById } from "../../services/GamePlayService";
import "../../css/ViewGame.css"
import ActionBtn from "../../buttons/ActionBtn";
import { toast } from "react-hot-toast";
import { CaretRight } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { PlayerList } from "./PlayerList";
import { convertUtcToLocal } from "../../utils/dateUtils";
import { updateGamePlay } from "../../services/GamePlayService";
import { useDidMountEffect, useTraceUpdate } from "../../hooks/genericHooks";
import GameHeader from "./GameHeader";
import { useGetData } from "../../hooks/ApiHooks/useGetData";
import { useGetToken } from "../../hooks/ApiHooks/useGetToken";
import { ShimmerTable } from "react-shimmer-effects";

const ViewGame = (props) => {
    const gamePlayId = props.id;
    const [gameData, setGameData, gameDataError] = useGetData(getGamePlayById, gameSettings, null, { id: gamePlayId });
    const [tempPlayerData, setTempPlayerData] = useState([]);
    const [loading, setIsLoading] = useState(false);
    const token = useGetToken();
    const navigate = useNavigate();

    useEffect(() => {
        gameData?.players.length > 0 && setTempPlayerData(gameData?.players);
    }, [gameData]);

    useDidMountEffect(() => {
        saveGamePlayChanges();
    }, [gameData]); 

    const handlePlayGameClick = () => navigate(`/game/play/${gamePlayId}`);

    const handlePlayerNameChange = ({newName, playerId, submitChange}) => {
        let newPlayerSettings = tempPlayerData.map(p => {
            return p.id == playerId ? {...p, name: newName} : p 
        });

        let valueIsDifferent = gameData.players.filter(p => p.id == playerId)[0].name != newName;
        if (!valueIsDifferent) return;

        setTempPlayerData(newPlayerSettings);
        if (submitChange) {
            setGameData({...gameData, players: newPlayerSettings})
        }
    }

    const saveGamePlayChanges = async () => {
        try {
            let res = await updateGamePlay(gameData, token);
            if (res.error) throw new Error(res.error.message);
            toast.success("game settings saved!")
        }
        catch (error) {
            console.error(error);
            toast.error("There was an error saving the game settings. Please try again.");
        }
    }

    return (
        <>
            <div className="view-game-outer">
                <GameHeader gameName={gameData?.gameInfo?.name} numPlayers={gameData?.numPlayers} />
                    {gameDataError && <div className="error-msg">There was an error loading the game data. Please try again.</div>}
                    {tempPlayerData.length == 0 && <ShimmerTable row={1} width={100} />}
                    {tempPlayerData?.length > 0 && <PlayerList 
                        isEditable={true}
                        players={tempPlayerData}
                        handleNameChange={handlePlayerNameChange}
                    />}
                <div className="view-game-bottom-row">
                    <div className="standard-date-stamp">Created {gameData?.dateCreatedUTC && convertUtcToLocal(gameData?.dateCreatedUTC)}</div>
                    <ActionBtn 
                        style={{alignSelf: "flex-end", fontFamily: "var(--font2)", fontWeight: "400"}} 
                        className="btn btn3" 
                        onClick={handlePlayGameClick}
                        loading={loading}
                        icon={<CaretRight size={20} weight="bold"/>}>
                            {gameData?.gameStatus == "Active" ? "Resume" : "Play!"}
                    </ActionBtn>
                </div>
            </div>
        </>
    )
}

export default ViewGame;