import ApiHelper, { callExternalApi } from "./ExternalApiService";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const getGames = async (queryConfig) => {
    const config = {
        url: `${baseUrl}/game/getavailable`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${queryConfig.accessToken}`,
        }
    }

    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
}

export const getGameTemplateById = async (queryConfig) => {
    const config = {
        url: `${baseUrl}/game`,
        params: queryConfig.params,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${queryConfig.accessToken}`,
        }
    }

    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
}