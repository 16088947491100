export function convertUtcToLocal(utcTimeString) {
    // Parse UTC time string to Date object
    const utcDate = new Date(utcTimeString);

    // Format local time using Intl.DateTimeFormat
    const localTime = new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        // hour: 'numeric',
        // minute: 'numeric',
        // second: 'numeric',
        // timeZoneName: 'short'
    }).format(utcDate);

    return localTime;
}