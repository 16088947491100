import Loader from "../General/Loader";

export default function GamesListDropdown({gamesList, selectedGame, handleGameChange}) {
    return (
        gamesList.length == 0 ? <div className="dropdown-placeholder"><Loader background="var(--bdColorBlue)" /> Loading games...</div> : 
            <select placeholder="loading games..." onChange={handleGameChange} value={selectedGame?.id} className="dropdown-primary">
                {gamesList?.map((game, i) => 
                    <option value={game.id} key={game.id}>{game.name}</option>
                )}
            </select>
    )
}