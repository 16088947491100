import { useParams } from "react-router-dom";
import PlayGame from "../components/Gameplay/PlayGame";

const PlayGamePage = () => {
    let { id } = useParams();

    return (
        <PlayGame id={id} />
    )
}

export default PlayGamePage;