import GamesListDropdown from "../GameLibrary/GamesList"
import { CaretRight } from "@phosphor-icons/react";
import "../../css/NewGame.css"
import { useState, useEffect } from "react";
import { newGameSettings } from "../../utils/constants";
import { getGames } from "../../services/GameTemplateService";
import ActionBtn from "../../buttons/ActionBtn";
import { createGame } from "../../services/GamePlayService";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import NumericInput from "../Inputs/NumericInput";
import { useGetData } from "../../hooks/ApiHooks/useGetData";
import { useGetToken } from "../../hooks/ApiHooks/useGetToken";

const NewGame = ({email}) => {
    const [gameSettings, setGameSettings] = useState({...newGameSettings, createdByEmail: email})
    const [data, setGamesList] = useGetData(getGames, []);
    const [loading, setLoading] = useState(false);
    const token = useGetToken();
    const navigate = useNavigate();

    useEffect(() => {
        if (data.length > 0 && gameSettings.id == null) setGameSettings({...gameSettings, gameTemplateId: data[0].id});
    }, [data]);

    function handleGameChange(e) {
        setGameSettings({...gameSettings, gameTemplateId: e.target.value});
    }

    function handleNumPlayerChange(newPlayerCount) {
        setGameSettings({...gameSettings, numPlayers: parseInt(newPlayerCount)});
    }

    async function handleCreateGameClick() {
        setLoading(true);
        try {
            let { data, error } = await createGame(gameSettings, token);
            toast.success("Game created!");
            setTimeout(() => {
                navigate(`/game/view/${data.id}`)
            }, 500)
        }
        catch (error) {
            toast.error("there was an issue creating your game.");
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <div className="new-game-outer">
            <div className="new-game-header">Create a Game</div>
            <div className="new-game-section">
                <div className="new-game-label">select a game:</div>
                <GamesListDropdown
                    gamesList={data}
                    selectedGame={gameSettings.gameTemplateId}
                    handleGameChange={handleGameChange}
                />
            </div>
            <div className="new-game-section">
                <div className="new-game-label">number of players:</div>
                <NumericInput value={gameSettings.numPlayers} onChange={handleNumPlayerChange} />
            </div>
            <ActionBtn 
                style={{alignSelf: "flex-end"}} 
                className="btn btn2" 
                onClick={handleCreateGameClick}
                loading={loading}
                icon={<CaretRight size={20} color="white" weight="bold"/>}>
                    Create Game
            </ActionBtn>
        </div>
    )
}

export default NewGame;