import Loader from "../components/General/Loader";

export default function ActionBtn({children, loading = false, text, onClick, className, style, icon}) {
    return (
        <button style={style} className={className} onClick={onClick}>
            {children}
            {!loading && icon}
            {loading && <Loader />}
        </button>
    )
}