
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import SignInPrompt from '../components/SignoutSignin/SignInPrompt';
import NewGame from '../components/Gameplay/NewGame';

const NewGamePage = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const email = activeAccount?.idTokenClaims && activeAccount.idTokenClaims["emails"][0];

    return (
        <>
            <AuthenticatedTemplate>
                <NewGame email={email} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <SignInPrompt prohibitedAction="create and play games" />
            </UnauthenticatedTemplate>
        </>
    );
};

export default NewGamePage;