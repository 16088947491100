import { useState, useEffect } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { protectedResources } from "../../auth/authConfig";  

export const useGetToken = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [token, setToken] = useState(null);

    useEffect(() => {
        if (instance && account) {
            instance.acquireTokenSilent({
                scopes: protectedResources.boardalyticsApi.scopes,
                account: account
            })
            .then((response) => {
                if(response) {
                    setToken(response.accessToken);
                }
                else {
                    console.error("No response from token request.");
                }
            });
        }
    }, [account, instance]);

    return token;
}