import { useParams } from "react-router-dom";
import ViewGame from "../components/Gameplay/ViewGame";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import SignInPrompt from '../components/SignoutSignin/SignInPrompt';
import "../css/ViewGame.css";

const ViewGamePage = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    let { id } = useParams();

    return (
        <>
            <AuthenticatedTemplate>
                <div className="standard-page-outer">
                    <div className="view-game-title">Game Details</div>
                    {id && <ViewGame id={id} />}
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <SignInPrompt prohibitedAction="view games" />
            </UnauthenticatedTemplate>
        </>
    )
}

export default ViewGamePage;