import "../../../css/GameScore.css";
import useFadeInAnimation from "../../../hooks/animationHooks";

export const ScoreBoard = ({players, gameTemplateInfo, gameScore, handleUpdateGameScore}) => {
    const scoringArtifacts = gameTemplateInfo?.scoringArtifacts?.map((a, i) => 
        <GameScore 
            key={a.id}
            players={players}
            scoreCategoryRules={a}
            currentScores={gameScore.scoreCategories.filter(s => s.scoringArtifactId == a.id)[0].playerScores}
            handleUpdateGameScore={handleUpdateGameScore}
        />
    )

    return (
        scoringArtifacts
    )
}

export const GameScore = ({scoreCategoryRules, players, currentScores, handleUpdateGameScore}) => {
    const { ref } = useFadeInAnimation();
    const inputType = scoreCategoryRules.dataType == "bool" ? "checkbox" : scoreCategoryRules.dataType == "int" ? "number" : "number";

    const handleUpdateGameScoreIntermediate = (playerId, target) => {
        let value = scoreCategoryRules.dataType == "bool" ? target.checked : target.value;
        handleUpdateGameScore(scoreCategoryRules.id, playerId, value);
    }

    let scoreObjects = players.map(p => {
        let currentPlayerScore = currentScores?.filter(c => c.playerId == p.id)[0]?.score;
        return (
            <div key={p.id} className="scoreboard-input-wrapper">
                <input defaultChecked={currentPlayerScore} defaultValue={currentPlayerScore} onChange={(e) => handleUpdateGameScoreIntermediate(p.id, e.target)} className="scoreboard-input-numeric" key={p.id} data-playerid={p.id} type={inputType} />
            </div>
        )
    })

    return (
        <div ref={ref} className="score-category-row">
            <div className="score-category-name">{scoreCategoryRules.name}</div>
            {scoreObjects}
        </div>
    )
}