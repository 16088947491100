import { useGetData } from "../../hooks/ApiHooks/useGetData"
import { getMyGamePlays } from "../../services/GamePlayService"
import { LastGamePlay } from "./LastGamePlay";
import "../../css/ViewGame.css"
import { useAccount } from "@azure/msal-react";
import { Plus } from "@phosphor-icons/react";
import ActionBtn from "../../buttons/ActionBtn";
import { useNavigate } from "react-router-dom";
import Loader from "../General/Loader";
import useFadeInAnimation from "../../hooks/animationHooks";

export const MyPreviousGamePlays = () => {
    const [myGamePlays, setMyGamePlays] = useGetData(getMyGamePlays);
    const operativeWord = myGamePlays?.length == 1 ? "play" : "plays";
    const account = useAccount();
    const navigate = useNavigate();
    const loadingPastGames = myGamePlays == null;
    const newGameNav = () => navigate("/game/new")
    const { ref } = useFadeInAnimation();

    const previousGameTiles = myGamePlays?.length > 0 && myGamePlays.map(g => 
        <LastGamePlay key={g.id} gameplayData={g} />
    )

    return (
        <div className="my-games">
            <div className="welcome-back-msg">Welcome back, {account?.idTokenClaims?.given_name}!</div>
            <div className="my-past-games">
                {loadingPastGames ? <div className="loader-text">Loading game history... <Loader /></div> : 
                    <div className="my-past-games-count" ref={ref}>
                        <span>{myGamePlays.length}</span> past game {operativeWord}
                    </div>
                }
                <ActionBtn
                    icon={<Plus size={28} color="var(--bdColorWhite)" weight="light" />}
                    onClick={newGameNav}
                    className={"btn btn1"}>
                    New Game
                </ActionBtn>
            </div>
            <div className="previous-game-list">
                {previousGameTiles}
            </div>
        </div>
    )

}