import React from 'react';
import { FlagCheckered } from '@phosphor-icons/react';

const EndGameButton = ({handleTriggerEndgame}) => {
    return (
        <button className='btn btn4' onClick={handleTriggerEndgame}>
            End Game <FlagCheckered weight='fill' size={25} />
        </button>
    );
};

export default EndGameButton;
