import { User } from "@phosphor-icons/react/dist/ssr";
import { getRandomCompatibleColor } from "../../utils/colorUtils";
import { useRef } from "react";
import "../../css/PlayerList.css"

export const Player = ({playerData, isEditable, nameChangeHandler, iconBackground}) => {    
    const handleNameChange = (e, isFinalChange) => {
        nameChangeHandler({
            newName: e.target.value, 
            playerId: playerData.id,
            submitChange: isFinalChange
        })
    }

    return (
        <div className="view-game-player-outer">
            <User className="view-game-player-icon" size={25} style={{background: iconBackground}} color="white" />
            {isEditable ? <input onBlur={(e) => handleNameChange(e, true)} onChange={(e) => handleNameChange(e, false)} className="standard-text-input" value={playerData.name} /> :
                <div>{playerData.name}</div>}

        </div>
        
    )
}

export const PlayerList = (props) => {
    const playerColors = useRef(props.players.map(p => getRandomCompatibleColor()));

    return (
        <div className={`view-game-player-list ${props.isPlayMode && 'player-list-play-mode'}`}>
            {props.isPlayMode && <div className="scoreboard-placeholder"></div>}
            {
                props.players?.map((p, i) => 
                    <Player iconBackground={playerColors.current[i]} nameChangeHandler={props.handleNameChange} key={p.id} isEditable={props.isEditable} playerData={p} />
                )
            }
        </div>
    )
}

