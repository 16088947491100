import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser"; 
import { loginRequest, b2cPolicies } from '../../auth/authConfig';
import LoginBtn from '../../buttons/LoginBtn';

export const NavigationBar = () => {
    const { instance, inProgress } = useMsal();
     let activeAccount;

     if (instance) {
         activeAccount = instance.getActiveAccount();
     }

    const handleLoginPopup = () => {
        instance
            .loginPopup({
                ...loginRequest,
                redirectUri: '/redirect',
            })
            .catch((error) => console.log(error));
    };

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        instance.logoutRedirect();
    };

    const handleLogoutPopup = () => {
        instance.logoutPopup({
            mainWindowRedirectUri: '/', // redirects the top level app after logout
        });
    };

    const handleProfileEdit = () => {
        if(inProgress === InteractionStatus.None){
           instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
        }
    };
    
    return (
        <>
            <nav className="navbar-custom">
                <a href="/#" className="navbar-brand-custom">
                    <div className="nav-logo"></div>
                    BOARDALYTICS
                </a>
                <AuthenticatedTemplate>
                    <div className="justify-content-end">
                        {/* <button variant="info" onClick={handleProfileEdit} className="profileButton">
                            Edit Profile
                        </button> */}
                        <div
                            variant="warning"
                            drop="start"
                            title={activeAccount?.idTokenClaims?.given_name ? activeAccount?.idTokenClaims?.given_name : 'Unknown'}
                        >
                            <button className='btn btn1' onClick={handleLogoutRedirect}>
                                Sign out
                            </button>
                        </div>
                    </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <LoginBtn loginRedirect={handleLoginRedirect} />
                </UnauthenticatedTemplate>
            </nav>
        </>
    );
};