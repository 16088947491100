import { useGetToken } from "./useGetToken";
import { useState, useEffect } from "react";

export const useGetData = (serviceFunction, defaultValue = null, payload = null, queryParams = null, dependencies = [], prerequisiteFunction = null) => {
    const [instanceData, setData] = useState(defaultValue);
    const [instanceError, setInstanceError] = useState(null);
    const token = useGetToken();

    useEffect(() => {
        const getData = async () => {
            let queryConfig = {
                accessToken: token,
                payload: payload,
                params: queryParams
            };
            if (payload) queryConfig.payload = payload;
            const { data, error } = await serviceFunction(queryConfig);
            if (error) {
                setInstanceError(error);
                console.error(error);
            } else {
                setData(data);
            }
        }
        if ((prerequisiteFunction == null || prerequisiteFunction()) && token) getData();

    }, [...dependencies, serviceFunction, token, payload]);

    return [instanceData, setData, instanceError];
}