import { convertUtcToLocal } from "../../utils/dateUtils";
import { useNavigate } from "react-router-dom";
import "../../css/ViewGame.css"
import { ArrowCircleRight } from "@phosphor-icons/react";
import ActionBtn from "../../buttons/ActionBtn";

export const LastGamePlay = ({gameplayData}) => {
    const navigate = useNavigate();

    const viewGame = () => navigate(`/game/view/${gameplayData.id}`)

    return (
        <div className="view-game-mini" onClick={viewGame}>
            <div className="view-game-mini-info">
                <div className="view-game-mini-name">{gameplayData.gameInfo.name}</div>
                <div>{gameplayData.numPlayers} players</div>
                <div className="standard-date-stamp">last played {convertUtcToLocal(gameplayData.lastModifiedDateUTC)}</div>
            </div>
           
            <ActionBtn 
                style={{"fontFamily": "var(--font2)"}}
                className="btn btn3" 
                onClick={viewGame}
                loading={false}
                icon={<ArrowCircleRight size={28} color="var(--bdColorBlue)" weight="fill" />}>
                    Resume Game
            </ActionBtn>
        </div>
    )
}