const NumericInput = ({value, onChange, includeButtons = true}) => {
    const handleIncrement = () => {
        onChange(value + 1);
      };
    
    const handleDecrement = () => {
        onChange(value - 1);
    };

    const handleInputChange = (e) => {
        onChange(Number(e.target.value));
    };

    return (
        <div className="input-primary-wrapper">
        {includeButtons && (
            <>
                <button className="input-primary-increment-btn" onClick={handleDecrement}>-</button>
                <input className="input-primary" type="number" value={value} readOnly />
                <button className="input-primary-increment-btn" onClick={handleIncrement}>+</button>
            </>
        )}
        {!includeButtons && <input type="number" value={value} onChange={handleInputChange} />}
        </div>
    )
}

export default NumericInput;