import "../../css/ViewGame.css"
import { Spade } from "@phosphor-icons/react";
import BackBtn from "../../buttons/BackBtn";
import EndGameButton from "./EndGameButton";

const GameHeader = ({gameName, numPlayers, className, isPlaying, handleEndgameTrigger = null}) => {
    const useIcon = false;

    return (
        <div className={`view-game-header ${className ?? ""}`}>
            <BackBtn />
            {useIcon && <div className="view-game-header-icon">
                <Spade size={40} color="var(--bdColorRed)" weight="fill" />
            </div>}
            <div className="view-game-header-text">
                <div className="view-game-name">{gameName}</div>
                <div className="view-game-player-cnt">{numPlayers} players</div>
            </div>
            {isPlaying && <div className="view-game-now-playing">Now playing</div>}
            {handleEndgameTrigger && <EndGameButton handleTriggerEndgame={handleEndgameTrigger} />}
        </div>
    )
}

export default GameHeader;