import { HomePage } from "../pages/HomePage";
import NewGamePage from "../pages/NewGamePage";
import PlayGamePage from "../pages/PlayGamePage";
import ViewGamePage from "../pages/ViewGamePage";

export const routes = [
    { path: '/', component: <HomePage /> },
    { path: 'game/new', component: <NewGamePage /> },
    { path: 'game/view/:id', component: <ViewGamePage /> },
    { path: 'game/play/:id', component: <PlayGamePage /> }
]