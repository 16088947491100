export const getRandomCompatibleColor = () => {
    const colors = [
        "#ff5252",    // Red
        "#ffd740",    // Amber
        "#ff4081",    // Pink
        "#536dfe",    // Indigo
        "#ff9800",    // Orange
        "#ffeb3b",    // Yellow
        "#f44336",    // Light Blue
        "#673ab7",    // Deep Purple
        "#4caf50",    // Teal
        "#795548",    // Brown
        "#607d8b",    // Blue Grey
        "#e91e63",    // Pink
        "#9c27b0",    // Purple
        "#2196f3",    // Blue
        "#009688",    // Teal
        "#ff5722",    // Deep Orange
        "#8bc34a",    // Light Green
        "#cddc39",    // Lime
        "#03a9f4",    // Light Blue
        "#689f38",    // Green
        "#3f51b5"     // Indigo
      ];      
      
    return colors[Math.floor(Math.random() * colors.length)];
}