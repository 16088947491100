import React from 'react';
import { CaretCircleLeft } from '@phosphor-icons/react';

const BackBtn = () => {
  const goBack = () => {
    window.history.back();
  };

  return (
    <button className='btn btnBack' onClick={goBack}>
      <CaretCircleLeft size={25} color="#dedede" weight="fill" /> Back
    </button>
  );
};

export default BackBtn;
