import "../../css/banner.css";
import { ArrowCircleRight } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

export const Landing = () => {

    return (
        <div className="banner">
            <div className="banner-text">
                <div className="landing-title">Welcome to Boardalytics</div>
                <div className="landing-subtitle">The smartest way to (board) game.</div>
                <div className="landing-button-container">
                    <Link to={"game/new"} className="hero-btn btn big">Play <ArrowCircleRight size={32} /></Link>
                    <a href="#" className="btn1 btn big" style={{ marginLeft: '10px' }}>Learn More</a>
                </div>
            </div>
        </div>
    )
}