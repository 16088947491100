import LoginBtn from "../../buttons/LoginBtn";
import "../../css/SigninPrompt.css";
import { Flag } from "@phosphor-icons/react";
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../auth/authConfig';

export default function SignInPrompt(props) {
    const {instance} = useMsal();

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    return (
        <div className="sign-in-prompt">
            <Flag size={40} />
            <div className="sign-in-prompt-text">You must be signed in to {props.prohibitedAction}.</div>
            <LoginBtn loginRedirect={handleLoginRedirect} />
        </div>
    )
}