import "../../css/Loader.css"
import useFadeInAnimation from "../../hooks/animationHooks";

const Loader = (props) => {
  const customStyles = {};
  const { ref } = useFadeInAnimation();

  if (props.background) customStyles.background = props.background;
  if (props.scale) customStyles.transform = `scale(${props.scale})`;

  const loaderElement = 
    <div ref={ref} style={customStyles} className="loader-container">
      <div className="loader"></div>
    </div>

    if (props.fullScreen) {
      return (
        <div className="full-screen-loader">
          {loaderElement}
        </div>
      );
    }

    return (
      loaderElement
    );
  };
  
  export default Loader;