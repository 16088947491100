export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_signupsignin_1",
        editProfile: "B2C_1_editprofile_1",
        forgotPassword: "B2C_1_resetpassword_1"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://boardalyticsauth.b2clogin.com/boardalyticsauth.onmicrosoft.com/B2C_1_signupsignin_1",
        },
        editProfile: {
            authority: "https://boardalyticsauth.b2clogin.com/boardalyticsauth.onmicrosoft.com/B2C_1_editprofile_1"
        }
    },
    authorityDomain: "boardalyticsauth.b2clogin.com"
};

export const loginRequest = {
    scopes: [],
};

export const msalConfig = {
    auth: {
        clientId: '24ce6d86-c811-471c-9c23-0c2f326da603',
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: '/', 
    },
}

export const protectedResources = {
 boardalyticsApi : {
   scopes: [
        "https://boardalyticsauth.onmicrosoft.com/boardalytics-api/Games.Read", 
        "https://boardalyticsauth.onmicrosoft.com/boardalytics-api/Gameplay.Create"
    ],
 },
}