// useSlideFadeInAnimation.js
import { useEffect, useRef } from 'react';

const useFadeInAnimation = () => {
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      // Initial styles before animation
      element.style.opacity = 0;
      element.style.overflow = 'hidden';
      
      // Triggering reflow to apply initial styles
      element.getBoundingClientRect();

      // Adding CSS transitions for smooth animation
      element.style.transition = 'opacity 0.5s ease';

      // Applying final styles for fade-in and slide-up
      element.style.opacity = 1;
      element.style.overflow = '';
    }
  }, []);

  return { ref: elementRef };
};

export default useFadeInAnimation;
