import Confetti from 'react-confetti'

export const GameResults = ({gamePlay}) => {
    const victorName = gamePlay.players.filter(player => player.id === gamePlay.victory.victor)[0].name;

    return (
        <>
            <Confetti />
            <div>
                <h1>Game Results</h1>
                <p>{victorName} wins!</p>
            </div>
        </>
    )
}