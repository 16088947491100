import { getGamePlayById, triggerEndGame, updateGameScore } from "../../services/GamePlayService";
import { PlayerList } from "./PlayerList";
import { ScoreBoard } from "./Scoring/GameScore";
import GameHeader from "./GameHeader";
import "../../css/PlayGame.css";
import { useGetData } from "../../hooks/ApiHooks/useGetData";
import { gameSettings } from "../../utils/constants";
import { getGameTemplateById } from "../../services/GameTemplateService";
import { useGetToken } from "../../hooks/ApiHooks/useGetToken";
import { ShimmerBadge, ShimmerTable, ShimmerTitle } from "react-shimmer-effects";
import { useState } from "react";
import { toast } from 'react-hot-toast';
import Loader from "../General/Loader";
import { Modal } from "../General/Modal";
import { GameResults } from "./GameResults";

const PlayGame = (props) => {
    const gamePlayId = props.id;
    const token = useGetToken();
    const [gameData, setGameData, error] = useGetData(getGamePlayById, gameSettings, null, { id: gamePlayId });
    const [showResults, setShowResults] = useState(false);
    const [gameTemplateInfo, setGameTemplateInfo] = useGetData(
        getGameTemplateById, 
        null, 
        null, 
        { gameTemplateId : gameData?.gameInfo?.gameTemplateId },
        [gameData],
        () => gameData?.gameInfo?.gameTemplateId != null
    );
    const isLoading = gameData.players == null || gameTemplateInfo == null;
    const [isEndgameSubmitted, setIsEndgameSubmitted] = useState(false);

    const handleEndgameTrigger = async () => {
        setIsEndgameSubmitted(true);
        const queryParams = {
            params: {id: gamePlayId}
        }
        triggerEndGame(queryParams, token)
            .then((response) => {
                toast.success("Game ended successfully! Loading your results..");
                setGameData(response.data);
                setShowResults(true);
            })
            .catch((ex) => {
                console.error(ex);
                toast.error("An error occurred while ending the game. Please try again later.");
            })
            .finally(() => {
                setIsEndgameSubmitted(false);
            });
    }

    const handleUpdateGameScore = (scoringArtifactId, playerId, value) => {
        var payload = {
            gamePlayId: gamePlayId,
            gameActionSequences: [
                {
                    playerId: playerId,
                    scoringArtifactId: scoringArtifactId,
                    value: value
                }
            ]
        }
        updateGameScore(payload, token);
    }
    
    const scoreBoard = <ScoreBoard
                            players={gameData.players} 
                            gameTemplateInfo={gameTemplateInfo} 
                            gameScore={gameData.gameScore} 
                            handleUpdateGameScore={handleUpdateGameScore}
                        />
    
    return (
        <>
            <div className="game-page-outer">
                <GameHeader 
                    className="play-game-header" 
                    gameName={gameData?.gameInfo?.name} 
                    numPlayers={gameData?.players?.length} 
                    isPlaying={true}
                    handleEndgameTrigger={handleEndgameTrigger}
                />
                <div className="gameplay-area">
                    <div className="gameplay-scorecard-label">Scorecard</div>
                    <div className="score-board">
                        {gameData?.players?.length > 0 && 
                            <PlayerList
                                isPlayMode={true} 
                                isEditable={false} 
                                players={gameData.players} 
                            />
                        }
                        {isLoading && <ShimmerTable width={100} />}
                        {scoreBoard}
                    </div>
                </div>
            </div>
            {isEndgameSubmitted && <Loader scale={2.5} fullScreen={true} />}
            {showResults && <Modal handleSetDisplayModal={() => setShowResults(false)} headerText={"Game Over!"}><GameResults gamePlay={gameData} /></Modal>}
        </>
    )
}

export default PlayGame;