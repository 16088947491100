import React, { useEffect, useRef, useState } from 'react';

export const useDidMountEffect = (func, deps) => {
    const mutationCounter = useRef(0);

    useEffect(() => {
        if (mutationCounter.current > 1)  {
            func();
        }
        else {
            mutationCounter.current = mutationCounter.current + 1;
        } 
    }, deps);
}

export function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
      const changedProps = props && Object.entries(props).reduce((ps, [k, v]) => {
        if (prev.current[k] !== v) {
          ps[k] = [prev.current[k], v];
        }
        return ps;
      }, {});
      if (props && Object.keys(changedProps).length > 0) {
        console.log('Changed props:', changedProps);
      }
      prev.current = props;
    });
  }