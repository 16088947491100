import ApiHelper, { callExternalApi } from "./ExternalApiService";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const createGame = async (gamePlayData, accessToken) => {
    const config = {
        url: `${baseUrl}/game/new`,
        data: gamePlayData,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        }
    }

    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
}

export const getGamePlayById = async (queryConfig) => {
    const config = {
        url: `${baseUrl}/game/getgameplay`,
        data: null,
        params: queryConfig.params,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${queryConfig.accessToken}`,
        }
    }

    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
}

export const updateGamePlay = async (gameData, accessToken) => {
    const config = {
        url: `${baseUrl}/game/updateGamePlay`,
        data: gameData,
        method: "PUT",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        }
    }

    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
}

export const updateGameScore = async (gamePlayActionData, accessToken) => {
    const config = {
        url: `${baseUrl}/GameScore`,
        data: gamePlayActionData,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        }
    }

    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
}

export const triggerEndGame = async (queryConfig, accessToken) => {
    const config = {
        url: `${baseUrl}/gameplay/end`,
        data: null,
        params: queryConfig.params,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        }
    }

    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
}

export const getMyGamePlays = async (queryConfig) => {
    const config = {
        url: `${baseUrl}/game/gameplays`,
        data: null,
        params: queryConfig.params,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${queryConfig.accessToken}`,
        }
    }

    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
}